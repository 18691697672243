import { action, makeObservable, observable, computed } from 'mobx';
import { ContractTypes } from '@draftkings/dk-data-layer';
import { IParser, ParserOptions, EventCard, LeagueCard, SBSport, SliderCard } from '../contracts';
import { SherpaData, ClankData } from '../contracts/retriever';
import { IRetriever } from '@draftkings/widgets-core/src/contracts';
import { parseLiveGameState, getTeamsInfo, getDefaultValue, hasSgpToggle, encodeLink } from '../utils';
import { format } from 'date-fns';
type ParserMobx = 'cards' | 'toggleCardId' | 'selectedCardId' | 'excludeId';
type Params = {
    events: ContractTypes.SportEvent[];
    leagues: ContractTypes.League[];
};
type CardTypeHandlerIParams = {
    event?: ContractTypes.SportEvent;
    league?: ContractTypes.League;
    widgetId?: string;
    index: number;
};

const outrightParticipants: Record<string, string> = {
    multiteam: 'multiteam',
    multiplayer: 'multiplayer'
};

export class Parser implements IParser {
    private retriever: IRetriever<SherpaData | ClankData>;
    private sports: SBSport[];
    private widgets: ContractTypes.Widget[];
    private logos: ParserOptions['logos'];
    private productConfig: ParserOptions['productConfig'];
    private selectedCardId: string;
    private sliderType: string;
    private excludeId: string;
    private cardsHandlers: Record<string, (params: Params) => (EventCard | LeagueCard)[]>;
    private cardTypeHandler: Record<string, (params: CardTypeHandlerIParams) => EventCard | LeagueCard | undefined>;
    private staticS3Host: string;

    constructor(options: ParserOptions) {
        this.retriever = options.retriever;
        this.sports = options.sports;
        this.widgets = options.widgets;
        this.logos = options.logos;
        this.productConfig = options.productConfig;
        this.sliderType = options.sliderType;
        this.selectedCardId = '';
        this.excludeId = options.excludeId;
        (this.staticS3Host = options.staticS3Host),
            (this.cardsHandlers = {
                widget: this.createWidgetCards,
                event: this.createEventCards
            });
        this.cardTypeHandler = {
            League: this.createLeagueCard,
            Event: this.createEventCard
        };

        makeObservable<IParser, ParserMobx>(this, {
            cards: computed,
            selectedCardId: observable,
            excludeId: observable,
            toggleCardId: action
        });
    }

    private createEventCards: (params: Params) => EventCard[] = ({ events, leagues }) => {
        return events
            .filter((event) => event.id !== this.excludeId)
            .sort((e1, e2) => {
                const date1 = new Date(e1.startEventDate);
                const date2 = new Date(e2.startEventDate);
                if (date1 > date2) {
                    return 1;
                }
                if (date2 > date1) {
                    return -1;
                }
                return 0;
            })
            .map((event, index) =>
                this.createEventCard({
                    event,
                    league: leagues[0],
                    index
                })
            )
            .filter((card): card is EventCard => card !== undefined);
    };
    private isSelected = (id: string, index: number) =>
        this.selectedCardId ? id === this.selectedCardId : index === 0;
    private createEventCard = ({ event, league, widgetId, index }: CardTypeHandlerIParams) => {
        if (
            event &&
            !outrightParticipants[getDefaultValue(event.eventParticipantType?.replace(/\s+/g, '')?.toLowerCase(), '')]
        ) {
            const eventStatus = parseLiveGameState(event.liveGameState, event.status);
            const id = getDefaultValue(widgetId, event.id);
            const { teams, scores } = getTeamsInfo({
                mainScorecard: event.eventScorecard?.mainScorecard,
                participants: getDefaultValue(event.participants, []),
                logosMap: this.logos[getDefaultValue(event.leagueId, getDefaultValue(league?.id, ''))],
                staticS3Host: this.staticS3Host
            });
            const eventCard: EventCard = {
                id,
                type: 'event',
                isLive: event.status === 'STARTED',
                isSelected: this.isSelected(id, index) && this.sliderType !== 'event',
                isSgp: hasSgpToggle(this.productConfig, getDefaultValue(event.tags, [])),
                description: 'BET NOW',
                teams,
                scores,
                name: event.name,
                startDate: format(event.startEventDate, 'M/D h:mm aa'),
                period: eventStatus.period,
                timer: eventStatus.timer,
                href: this.sliderType === 'event' ? `/event/${encodeLink(event.name)}/${event.id}` : ''
            };
            return eventCard;
        }

        return undefined;
    };

    private createLeagueCard = ({ league, widgetId, index }: CardTypeHandlerIParams) => {
        if (league) {
            const events = Array.from(this.retriever.data.events.values());
            const liveEvents = events.filter((event) => event.leagueId === league.id && event.status === 'STARTED');
            const id = getDefaultValue(widgetId, league.id);
            const tagsByLeague = events.reduce((acc: Record<string, string[]>, e: ContractTypes.SportEvent) => {
                if (!acc[e.leagueId]) {
                    acc[e.leagueId] = [];
                }
                e.tags && acc[e.leagueId].push(...e.tags);
                return acc;
            }, {});

            const leagueCard: LeagueCard = {
                id,
                type: 'league',
                sportName: getDefaultValue(
                    this.sports.find((sport) => sport.displayGroupId === league.sportId)?.nameIdentifier,
                    ''
                ),
                leagueName: league.name,
                isLive: liveEvents.length > 0,
                isSelected: this.isSelected(id, index),
                isSgp: hasSgpToggle(this.productConfig, getDefaultValue(tagsByLeague[league.id], [])),
                description: 'BET NOW',
                name: league.name
            };

            return leagueCard;
        }
        return undefined;
    };

    private createWidgetCards: (params: Params) => (EventCard | LeagueCard)[] = ({ events, leagues }) => {
        return this.widgets
            .map((w, index) => {
                const cardTypeHandler = this.cardTypeHandler[w.widgetType];
                const cardTypeHandlerParams = {
                    event: events.find((e) => e.id === w.eventId),
                    league: leagues.find((l) => l.id === w.leagueId),
                    widgetId: w.id,
                    index
                };
                return cardTypeHandler(cardTypeHandlerParams);
            })
            .filter((card): card is EventCard | LeagueCard => card !== undefined);
    };

    get cards(): SliderCard[] {
        const handler = this.cardsHandlers[this.sliderType];
        return handler({
            events: Array.from(this.retriever.data.events.values()),
            leagues: Array.from(this.retriever.data.leagues.values())
        });
    }

    toggleCardId(cardId: string): void {
        if (this.sliderType === 'event') {
            this.excludeId = cardId;
            return;
        }

        this.selectedCardId = cardId;
    }

    activate(): void {}
    deactivate(): void {}
}
