import { XClientHeaders } from '../../contracts/external';

export const getWebHeaders = (consumerVersion: string, feature: string, page: string): XClientHeaders => ({
    'X-Client-Name': 'web',
    'X-Client-Version': consumerVersion,
    'X-Client-Feature': feature,
    'X-Client-Page': page,
    'X-Client-Widget-Name': 'SportSliderWidget',
    'X-Client-Widget-Version': APP_VERSION
});
