import cx from 'classnames';
import { EventCard as EventCardType, LeagueCard as LeagueCardType } from '../../contracts';
import { TeamLogo } from '../TeamLogo';
import { SGPBadge, SGPBadgeDark } from '../SGPBadge';
import { Icon } from '../Icons';
import './index.scss';
import React from 'react';

export type EventCardProps = EventCardType & {
    onSgpClick: (event: React.MouseEvent) => void;
};
export type LeagueCardProps = LeagueCardType;

export const EventCard = ({
    teams,
    scores,
    startDate,
    period,
    timer,
    isSelected,
    isLive,
    isSgp,
    description,
    href,
    onSgpClick
}: EventCardProps) => {
    return (
        <div className={cx('sport-slider-card', { 'is-selected': isSelected })}>
            <div className="teams" key={teams.toString()}>
                {teams.map(({ logoUrl, name }, index) => (
                    <React.Fragment key={name + index}>
                        <div className="teams__row">
                            {logoUrl && (
                                <TeamLogo
                                    className="teams__row-logo"
                                    alt={`${name}-logo`}
                                    src={logoUrl}
                                    onErrorBehaviour="hide"
                                />
                            )}
                            {href ? (
                                <a className="team-name" href={href}>
                                    {name}
                                </a>
                            ) : (
                                <span className="team-name">{name}</span>
                            )}
                        </div>
                    </React.Fragment>
                ))}
            </div>
            <div className="scores">
                {scores.map((score, index) => (
                    <div className={cx('score')} key={index + score}>
                        {score}
                    </div>
                ))}
            </div>
            <div className="bottom">
                {isSgp && (
                    <>
                        {href ? (
                            <a
                                title="Open game in SGP mode"
                                className="bottom-sgp-wrapper"
                                href={`${href}?sgpmode=true`}
                                data-testid={'sgp-wrapper-link'}
                                onClick={onSgpClick}
                            >
                                <SGPBadge className="bottom-sgp-badge-icon" {...SGPBadgeDark} />
                            </a>
                        ) : (
                            <span className="bottom-sgp-wrapper" data-testid={'sgp-wrapper'} onClick={onSgpClick}>
                                <SGPBadge className="bottom-sgp-badge-icon" {...SGPBadgeDark} />
                            </span>
                        )}
                    </>
                )}
                <div className="bottom-live-in-game">
                    <>
                        {isLive ? (
                            <div className="bottom-event-cell__status">
                                <Icon name="live" data-test-id={'live'} />
                                <div className={cx('bottom-event-cell__clock')}>
                                    {timer && <span className={cx('bottom-event-cell__time')}>{timer}</span>}
                                    {period && <span className={cx('bottom-event-cell__period')}>{period}</span>}
                                </div>
                            </div>
                        ) : (
                            <span className="bottom-date">{startDate}</span>
                        )}
                    </>
                </div>
                {href ? (
                    <a className="bottom-bet-now" href={href}>
                        {description}
                    </a>
                ) : (
                    <span className="bottom-bet-now">{description}</span>
                )}
            </div>
        </div>
    );
};

export const LeagueCard = ({ sportName, leagueName, isSelected, isLive, isSgp, description }: LeagueCardProps) => (
    <div className={cx('sport-slider-card', { 'is-selected': isSelected })}>
        <div className="league">
            <div className="sport-name">{sportName}</div>
            <div className="league-name">{leagueName}</div>
        </div>
        <div className="bottom">
            <div className="bottom-icons-wrapper">
                {isSgp && <SGPBadge className="bottom-sgp-badge-icon" {...SGPBadgeDark} />}
                {isLive && <Icon name="live" data-test-id={'live'} />}
            </div>
            <span className={cx('bottom-bet-now')}>{description}</span>
        </div>
    </div>
);
