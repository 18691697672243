import React from 'react';
import { IState } from '../../contracts/state';
import { observer } from 'mobx-react';
import { Slider } from '../Slider';

type AppProps = {
    dataProvider: IState;
    renderLoader: () => JSX.Element;
};

class AppComponent extends React.Component<AppProps, IState> {
    constructor(props: AppProps) {
        super(props);
    }

    render() {
        return (
            <div>
                {this.props.dataProvider.isLoading && this.props.renderLoader()}
                {this.props.dataProvider.cards.length >= 0 && this.props.dataProvider.isLoaded && (
                    <Slider cards={this.props.dataProvider.cards} onClick={this.props.dataProvider.onCardClick} />
                )}
            </div>
        );
    }
}

export const App = observer(AppComponent);
