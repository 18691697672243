import React from 'react';

interface TeamLogoProps extends React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> {
    onErrorBehaviour: 'hide' | 'remove';
}

export class TeamLogo extends React.Component<TeamLogoProps> {
    private imgRef: React.RefObject<HTMLImageElement>;

    constructor(props: Readonly<TeamLogoProps>) {
        super(props);
        this.imgRef = React.createRef<HTMLImageElement>();
    }

    private handleImageAvailability = () => {
        const imageElement = this.imgRef.current;
        if (imageElement && imageElement.complete && imageElement.naturalHeight === 0) {
            this.hide(imageElement);
        }
    };

    private handleImageLoad = () => {
        this.imgRef.current && this.show(this.imgRef.current);
    };

    private hide = (element: HTMLImageElement) => {
        this.props.onErrorBehaviour === 'hide'
            ? (element.style.visibility = 'hidden')
            : (element.style.display = 'none');
    };

    private show = (element: HTMLImageElement) => {
        this.props.onErrorBehaviour === 'hide'
            ? (element.style.visibility = 'visible')
            : (element.style.display = 'initial');
    };

    componentDidMount() {
        const imageElement = this.imgRef.current;
        if (imageElement && !imageElement.complete) {
            this.hide(imageElement);
        }
        this.handleImageAvailability();
    }

    render() {
        return (
            <img
                ref={this.imgRef}
                onError={this.handleImageAvailability}
                onLoad={this.handleImageLoad}
                {...this.props}
            />
        );
    }
}
