import { BaseRetriever } from '@draftkings/widgets-core/src/retriever';
import { ContractTypes, Clank } from '@draftkings/dk-data-layer';
import { ClankData, ClankОptions, ConditionKeys } from '../../contracts/retriever';
import { IRetriever, ICondition, IConditionManager } from '@draftkings/widgets-core/src/contracts';
import { DataCondition } from '@draftkings/widgets-core/src/utils/Condition';
import { eventDeepMerge } from '../deepMerge';
import { getWebHeaders, getErrorDescription } from '../../utils';
export class ClankRetriver extends BaseRetriever<void, ClankData> implements IRetriever<ClankData> {
    private Clank: Clank;
    private SportsbookEvent: ContractTypes.SportsbooksEventConstructor;
    private zoneId: string;
    private widgets: ContractTypes.Widget[];
    private condition: IConditionManager<typeof ConditionKeys> & ICondition;
    private logError: ClankОptions['logError'];
    private trackEvent: ClankОptions['trackEvent'];

    constructor(options: ClankОptions) {
        super(options);
        this.Clank = new options.Clank({
            ...getWebHeaders(options.consumerVersion, 'widget-zones', 'home')
        });
        this.logError = options.logError;
        this.trackEvent = options.trackEvent;
        this.condition = options.condition;
        this.condition.set('Retriever', options.zoneId ? DataCondition.LOADING : DataCondition.LOADED);
        this.SportsbookEvent = options.SportsbookEvent;
        this.widgets = options.widgets;
        this.zoneId = options.zoneId;
    }

    protected subscribe = () => {
        const ids = this.widgets.map((widget) => `'${widget.eventId}'`).join(',');
        let subscription;
        if (ids) {
            subscription = new this.SportsbookEvent(
                {
                    entity: 'events',
                    query: `$filter=id in (${ids})`,
                    includeMarkets: 'none'
                },
                'event',
                false,
                this.onDataCallback,
                this.onErrorCallback
            );
            this.trackSocketEvent('SUBSCRIBE', ids);
        }

        return () => {
            this.trackSocketEvent('UNSUBSCRIBE', ids);
            subscription?.deactivate();
        };
    };

    protected getQuery = (): void => undefined;

    protected getData = async (): Promise<ClankData> => {
        if (this.zoneId) {
            try {
                const data = await this.Clank.getWidgetZones({ zoneId: this.zoneId });
                if (data) {
                    this.condition.set('Retriever', DataCondition.LOADED);
                    return {
                        events: new Map<string, ContractTypes.SportEvent>(
                            data.events.map((event) => [event.id, event])
                        ),
                        leagues: new Map<string, ContractTypes.League>(
                            data.leagues.map((league) => [league.id, league])
                        )
                    };
                }
            } catch (error) {
                this.condition.set('Retriever', DataCondition.ERROR);
                this.logError({
                    statusCode: 'FETCH_WIDGET_ZONES_ERROR',
                    description: getErrorDescription(error),
                    id: this.zoneId,
                    widgetVersion: APP_VERSION
                });
            }
        }
        return this.data;
    };

    private onErrorCallback = (error: unknown) => {
        this.logError({
            statusCode: 'SocketConnectionError',
            description: getErrorDescription(error),
            id: this.widgets.map((widget) => `'${widget.eventId}'`).join(','),
            widgetVersion: APP_VERSION
        });
    };

    private onDataCallback = (response: ContractTypes.SportsbookPushResponse) => {
        this.add(response.data.add, response.metadata);
        this.change(response.data.change, response.metadata);
        this.remove(response.data.remove, response.metadata);
    };

    private add = (
        payload: ContractTypes.SportsbookPushResponse['data']['add'],
        metaData: ContractTypes.SportsbookPushResponse['metadata']
    ) => {
        payload.events.forEach((event) => {
            const { id } = event;
            const newEvent = { ...event };
            this.data.events.set(id, { ...newEvent });
            this.trackSocketEvent('ADD_EVENT', newEvent.id, metaData);
        });
    };

    private change = (payload: ContractTypes.SportsbookPushResponse['data']['change'], metadata) => {
        payload.events.forEach((newEvent) => {
            const existEvent = this.data.events.get(newEvent.id);
            if (existEvent) {
                eventDeepMerge(existEvent, {
                    ...newEvent
                });
                this.trackSocketEvent('UPDATE_EVENT', newEvent.id, metadata);
            }
        });
    };

    private remove = (
        payload: ContractTypes.SportsbookPushResponse['data']['remove'],
        metaData: ContractTypes.SportsbookPushResponse['metadata']
    ) => {
        payload.events.forEach((event) => {
            const existingEvent = this.data.events.get(event);
            if (existingEvent) {
                existingEvent.status = 'FINISHED';
                this.trackSocketEvent('REMOVE_EVENT', event, metaData);
            }
        });
    };

    private trackSocketEvent(clientAction: string, eventId?: string, metadata?: ContractTypes.Metadata) {
        const receivedAt = new Date().getTime();
        const publishedAt = metadata?.publishedTime ? new Date(metadata.publishedTime).getTime() : 0;
        const delta = publishedAt ? receivedAt - publishedAt : 0;
        this.trackEvent('LONGSHOT_EVENT', {
            clientAction,
            delta,
            zoneId: this.zoneId,
            eventId: eventId,
            pageName: 'HOME_PAGE',
            widget: 'SPORT_SLIDER_WIDGET',
            widgetVersion: APP_VERSION
        });
    }
}
