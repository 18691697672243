import { ContractTypes } from '@draftkings/dk-data-layer';

export type EventState = {
    timer: string;
    period: string;
    state: string;
};

export const parseLiveGameState = (
    liveGameState: ContractTypes.LiveGameState | undefined,
    state: string
): EventState => {
    const defaultStatus = {
        timer: '',
        period: liveGameState?.period || '',
        state
    };

    if (!liveGameState) {
        return defaultStatus;
    }

    if (liveGameState.gameTime && shouldCalculateGameTime(liveGameState.period)) {
        const { gameTime, period = '' } = liveGameState;
        const minute = Math.floor(gameTime / 60);
        const second = gameTime - minute * 60;
        const secondString = second.toString().padStart(2, '0');
        const timer = `${minute}:${secondString}`;

        return {
            timer,
            period,
            state
        };
    }

    return defaultStatus;
};

const shouldCalculateGameTime = (gamePart = ''): boolean =>
    !(gamePart.startsWith('BreakAfter') || gamePart === 'Finished');
