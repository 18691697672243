import React, { createRef } from 'react';
import cx from 'classnames';
import { Icon } from '../Icons';

import './TileSlider.scss';

type TileSliderProps = {
    children?: JSX.Element[];
    sliderWidth: number;
    tileWidth: number;
    offset: number;
    scrollMode: boolean;
    onClick: (key: number | string) => void;
    arrowDisplay: string;
    dynamicSlide: boolean;
    tileWidths: number[];
    shouldCalculateContainerWidth: boolean;
};

type TileSliderState = {
    cursor: number;
};

export class TileSlider extends React.Component<TileSliderProps, TileSliderState> {
    private listNode = createRef<HTMLUListElement>();

    constructor(props: TileSliderProps) {
        super(props);

        this.state = {
            cursor: 0
        };
    }

    componentDidUpdate(prevProps: TileSliderProps) {
        if (prevProps.children && this.props.children && prevProps.children.length !== this.props.children.length) {
            this.setState({ cursor: 0 });
        }
    }

    getRef = (node: React.RefObject<HTMLUListElement>) => {
        this.listNode = node;
    };

    resetScrollPosition = () => {
        if (this.listNode && this.listNode.current && this.listNode.current.scrollLeft > 0) {
            this.listNode.current.scrollLeft = 0;
        }
    };

    advanceCursor = () => {
        this.resetScrollPosition();

        const maxCursor = this.calculateMaxCursor();
        const canMoveForward = this.state.cursor < maxCursor;
        this.setState({
            cursor: canMoveForward ? this.state.cursor + 1 : 0
        });
    };

    retractCursor = () => {
        this.resetScrollPosition();

        const canMoveBackward = this.state.cursor > 0;
        if (canMoveBackward) {
            this.setState({
                cursor: this.state.cursor - 1
            });
        }
    };

    calculateTilesToShow = () => {
        // This calculation ensures the container shows as many
        // tiles as possible
        const { tileWidths, dynamicSlide } = this.props;
        if (dynamicSlide) {
            let acc = 0;
            for (let i = 0; i < tileWidths.length; i++) {
                const currWidth = tileWidths[i];
                acc += currWidth;
                if (acc > this.props.sliderWidth) {
                    return i;
                }
            }
            // return number of tiles if all can fit in slider
            return tileWidths.length;
        }

        return Math.floor(this.props.sliderWidth / this.props.tileWidth);
    };

    calculateMaxCursor = () => {
        // competitions divided by the amount per 'page'
        const { dynamicSlide, tileWidths } = this.props;
        const tilesToShow = this.calculateTilesToShow();
        const containerWidth = this.calculateContainerWidth();
        const tiles = this.props.children?.length || 0;
        if (dynamicSlide) {
            let remainingCardWidthToShow = tileWidths.length ? tileWidths.reduce((acc, val) => acc + val) : 0;
            for (let i = 0; i < tileWidths.length; i++) {
                if (remainingCardWidthToShow <= containerWidth) {
                    return i;
                }
                remainingCardWidthToShow -= tileWidths[i];
            }
            return tileWidths.length;
        }

        return tiles - tilesToShow;
    };

    calculateContainerWidth = () => {
        const { tileWidth, dynamicSlide } = this.props;
        if (dynamicSlide) {
            const acc = 0;
            if (this.listNode && this.listNode.current) {
                return this.listNode.current.offsetWidth;
            }
            return acc;
        }
        return this.calculateTilesToShow() * tileWidth;
    };

    calculateLastDynamicOffset = () => {
        const { tileWidths } = this.props;
        const containerWidth = this.calculateContainerWidth();
        const sumWidths = tileWidths.reduce((acc, val) => acc + val);
        return sumWidths - containerWidth;
    };

    calculateTranslateX = () => {
        const currentCursorLocation = this.state.cursor;
        const { tileWidth, tileWidths, dynamicSlide, offset } = this.props;

        if (currentCursorLocation === 0) {
            return 0;
        }
        if (dynamicSlide) {
            const seenTiles = tileWidths.slice(0, currentCursorLocation);
            const translateX = seenTiles.reduce((acc, val) => acc + val);
            if (currentCursorLocation === this.calculateMaxCursor()) {
                return this.calculateLastDynamicOffset();
            }
            return translateX;
        }

        return currentCursorLocation * (offset + tileWidth);
    };

    getListStyles = () => {
        const { scrollMode, shouldCalculateContainerWidth } = this.props;

        return {
            width:
                scrollMode || this.props.dynamicSlide || !shouldCalculateContainerWidth
                    ? '100%'
                    : `${this.calculateContainerWidth()}px`
        };
    };

    render() {
        const { scrollMode, arrowDisplay } = this.props;

        if (!this.props.children || !this.props.children.length) {
            return null;
        }

        const tileStyles = {
            transform: `translateX(-${this.calculateTranslateX()}px)`
        };

        const maxCursor = this.calculateMaxCursor();
        const showArrows = !scrollMode || !Number.isFinite(maxCursor);
        const atMax = this.state.cursor >= maxCursor;
        const advanceIcon = atMax ? 'refresh' : 'arrow-right';

        return (
            <div className={cx('slider-container')}>
                <ul
                    className={cx('list', { scrollMode }, 'sportsbook-custom-scrollbar-darkest')}
                    style={this.getListStyles()}
                    ref={this.listNode}
                >
                    {this.props.children.map((child, index) => (
                        <li
                            className={cx('item')}
                            key={child.key || `tile-child-${index}`}
                            style={tileStyles}
                            onClick={(event) => {
                                event.preventDefault();
                                this.props.onClick(child.key || index);
                            }}
                        >
                            {child}
                        </li>
                    ))}
                </ul>
                {showArrows && (
                    <div className={cx('sl-arrow-container', { 'display-row': arrowDisplay === 'row' })}>
                        <span
                            className={cx('sl-arrow', {
                                disabled: !this.state.cursor,
                                'display-row': arrowDisplay === 'row'
                            })}
                            data-testid="competition-filter-arrow-left"
                            onClick={this.retractCursor}
                        >
                            <Icon name="arrow-left" data-test-id={'arrow-left'} />
                        </span>
                        <span
                            className={cx('sl-arrow', { 'display-row': arrowDisplay === 'row' })}
                            data-testid="competition-filter-arrow-right"
                            onClick={this.advanceCursor}
                        >
                            <Icon name={advanceIcon} data-test-id={advanceIcon} />
                        </span>
                    </div>
                )}
            </div>
        );
    }
}
