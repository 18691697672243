import React from 'react';

type IconProps = {
    alt?: string;
    'data-test-id': string;
};

const Live: React.FC<IconProps> = ({
    alt = 'Icon indicating a live or in-progress event',
    'data-test-id': dataTestId
}: IconProps) => (
    <svg
        role="img"
        className="sportsbook__icon--live"
        aria-label={alt}
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="16"
        viewBox="0 0 24 16"
        fill="none"
        data-testid={dataTestId}
    >
        <rect x="0.5" y="0.5" width="23" height="15" rx="1.5" fill="white" fillOpacity="0.1" />
        <path
            d="M4.8784 11.5V4.62H6.2584V10.25H7.9984V11.5H4.8784ZM8.67746 11.5V4.62H10.0475V11.5H8.67746ZM11.9991 11.5L10.6791 4.62H12.0791L12.9791 10.06H13.0691L13.9391 4.62H15.3191L13.9991 11.5H11.9991ZM15.9526 11.5V4.62H19.2926V5.83H17.3326V7.42H19.0226V8.63H17.3326V10.29H19.2926V11.5H15.9526Z"
            fill="white"
        />
        <rect x="0.5" y="0.5" width="23" height="15" rx="1.5" stroke="white" />
    </svg>
);

const ArrowLeft: React.FC<IconProps> = ({
    alt = 'Arrow pointing left icon',
    'data-test-id': dataTestId
}: IconProps) => (
    <svg
        role="img"
        aria-label={alt}
        className={'sportsbook__icon--arrow-left'}
        data-testid={dataTestId}
        fill="#ababab"
        width="20"
        height="20"
        viewBox="0 0 32 32"
    >
        <path d="M5.504 15.968v0.032l14.656 14.624c0.384 0.384 0.992 0.384 1.344 0l1.504-1.504c0.384-0.384 0.384-0.992 0-1.344l-11.776-11.744v-0.032l11.776-11.776c0.384-0.384 0.384-0.992 0-1.344l-1.504-1.504c-0.384-0.384-0.992-0.384-1.344 0l-14.656 14.592z"></path>
    </svg>
);

const ArrowRight: React.FC<IconProps> = ({
    alt = 'Arrow pointing right icon',
    'data-test-id': dataTestId
}: IconProps) => (
    <svg
        role="img"
        aria-label={alt}
        className={'sportsbook__icon--arrow-right'}
        data-testid={dataTestId}
        fill="#ababab"
        width="20"
        height="20"
        viewBox="0 0 32 32"
    >
        <path d="M26.464 16.032v-0.032l-14.624-14.624c-0.384-0.384-0.992-0.384-1.344 0l-1.504 1.504c-0.384 0.384-0.384 0.992 0 1.344l11.776 11.776v0.032l-11.776 11.744c-0.384 0.384-0.384 0.992 0 1.344l1.504 1.504c0.384 0.384 0.992 0.384 1.344 0l14.624-14.592z"></path>
    </svg>
);

const Refresh: React.FC<IconProps> = ({
    alt = 'Icon representing a refresh action',
    'data-test-id': dataTestId
}: IconProps) => (
    <svg
        role="img"
        aria-labelledby="title"
        className={'sportsbook__icon--refresh'}
        data-testid={dataTestId}
        fill="#ababab"
        width="20"
        height="20"
        viewBox="0 0 32 32"
    >
        <title>{alt}</title>
        <path d="M28.32 18.624l-8.832 1.76c-0.224 0.064-0.384 0.288-0.384 0.576l0.352 1.92c0.064 0.224 0.32 0.448 0.576 0.384l3.008-0.64c-1.824 2.112-4.448 3.296-7.264 3.296-4.704 0-8.768-3.36-9.6-7.968l-4.416 1.248c1.44 6.528 7.328 11.264 14.016 11.264 4.224 0 8.128-1.824 10.848-4.992l0.48 2.048c0.064 0.224 0.288 0.384 0.576 0.384l1.92-0.352c0.224-0.064 0.384-0.288 0.384-0.576l-1.664-8.352zM3.552 13.664l8.832-1.76c0.224-0.064 0.384-0.288 0.384-0.576l-0.352-1.92c-0.064-0.224-0.32-0.448-0.576-0.384l-3.008 0.64c1.856-2.144 4.48-3.36 7.264-3.36 4.704 0 8.768 3.36 9.6 7.968l4.416-1.248c-1.44-6.464-7.328-11.2-14.016-11.2-4.224 0-8.128 1.824-10.848 4.992l-0.48-2.368c-0.064-0.224-0.288-0.384-0.576-0.384l-1.92 0.352c-0.224 0.064-0.384 0.288-0.384 0.576l1.664 8.672z"></path>
    </svg>
);

type IconMapProps = IconProps & {
    name: string;
};

const icons: Record<string, React.FC<IconProps>> = {
    'arrow-right': ArrowRight,
    'arrow-left': ArrowLeft,
    refresh: Refresh,
    live: Live
};

export const Icon: React.FC<IconMapProps> = ({ alt, name, 'data-test-id': dataTestId }: IconMapProps) => {
    if (!icons[name]) {
        return null;
    }

    const IconComponent = icons[name];

    return <IconComponent alt={alt} data-test-id={dataTestId} />;
};
