import { Team, LogosMap } from '../../contracts';
import { ContractTypes } from '@draftkings/dk-data-layer';
import { getDefaultValue } from '../getDefaultValue';

type GetTeamsInfoOptions = {
    mainScorecard: ContractTypes.MainScorecard | undefined;
    participants: ContractTypes.Participant[];
    logosMap: LogosMap | undefined;
    staticS3Host: string;
};

export const getTeamsInfo = ({
    mainScorecard,
    participants,
    logosMap,
    staticS3Host
}: GetTeamsInfoOptions): { teams: Team[]; scores: string[] } => {
    const logosTeam = logosMap?.Teams;
    const teamScoreMapper: { [key: number]: 'firstTeamScore' | 'secondTeamScore' } = {
        1: 'firstTeamScore',
        2: 'secondTeamScore'
    };
    const scores: string[] = [];
    const teams: Team[] = participants
        .filter((p) => p.type === 'Team')
        .sort((a, b) => {
            if (a.sortOrder && b.sortOrder) {
                return a.sortOrder - b.sortOrder;
            }

            return 0;
        })
        .map((participant, index) => {
            const participantId = index + 1;
            const score = mainScorecard ? mainScorecard[teamScoreMapper[participantId]] : '';
            scores.push(score);
            return {
                logoUrl: getDefaultValue(getLogoUrl(staticS3Host, logosTeam, participant.name), ''),
                name: participant.name
            };
        });

    return {
        teams,
        scores
    };
};

const getLogoUrl = (
    staticS3Host: string,
    logosTeam: LogosMap['Teams'] | undefined,
    name: ContractTypes.Participant['name']
): string | undefined =>
    staticS3Host && logosTeam && logosTeam[name] ? `${staticS3Host}${logosTeam?.[name]}` : undefined;
