import { BaseRetriever } from '@draftkings/widgets-core/src/retriever';
import { ContractTypes, Sherpa } from '@draftkings/dk-data-layer';
import { SherpaData, SherpaOptions, ConditionKeys } from '../../contracts/retriever';
import { IRetriever, ICondition, IConditionManager } from '@draftkings/widgets-core/src/contracts';
import { DataCondition } from '@draftkings/widgets-core/src/utils/Condition';
import { eventDeepMerge } from '../deepMerge';
import { action, makeObservable } from 'mobx';
import { getWebHeaders, getErrorDescription } from '../../utils';

type SherpaRetriverMobx = 'add' | 'change' | 'remove';
export class SherpaRetriver extends BaseRetriever<string, SherpaData> implements IRetriever<SherpaData> {
    private Sherpa: Sherpa;
    private SportsbookLeague: ContractTypes.SportsbookLeagueConstructor;
    private leagueId: string;
    private condition: IConditionManager<typeof ConditionKeys> & ICondition;
    private logError: SherpaOptions['logError'];
    private trackEvent: SherpaOptions['trackEvent'];

    constructor(options: SherpaOptions) {
        super(options);
        this.Sherpa = new options.Sherpa({
            ...getWebHeaders(options.consumerVersion, 'event-slider', 'event-page')
        });
        this.SportsbookLeague = options.SportsbookLeague;
        this.leagueId = options.leagueId;
        this.condition = options.condition;
        this.logError = options.logError;
        this.trackEvent = options.trackEvent;
        makeObservable<IRetriever<SherpaData>, SherpaRetriverMobx>(this, {
            add: action,
            change: action,
            remove: action
        });
    }
    protected subscribe = () => {
        const league = new this.SportsbookLeague(
            {
                entity: 'events',
                query: `$filter=leagueId eq '${this.leagueId}'`,
                includeMarkets: 'none'
            },
            'event',
            false,
            this.onDataCallback,
            this.onErrorCallback
        );
        this.trackSocketEvent('SUBSCRIBE');
        return () => {
            this.trackSocketEvent('UNSUBSCRIBE');
            league.deactivate();
        };
    };

    protected getQuery = (): string => {
        return this.leagueId;
    };

    protected getData = async (): Promise<SherpaData> => {
        try {
            const data = await this.Sherpa.getNavigationEventsData({
                leagueId: this.leagueId
            });

            if (data) {
                this.condition.set('Retriever', DataCondition.LOADED);
                return {
                    events: new Map<string, ContractTypes.SportEvent>(data.events.map((event) => [event.id, event])),
                    leagues: new Map<string, ContractTypes.League>(data.leagues.map((league) => [league.id, league]))
                };
            }
        } catch (error) {
            this.condition.set('Retriever', DataCondition.ERROR);
            this.logError({
                statusCode: 'FETCH_EVENTGROUP_EVENTS_FAILURE',
                description: getErrorDescription(error),
                id: this.leagueId,
                widgetVersion: APP_VERSION
            });
        }
        return this.data;
    };

    private onDataCallback = (response: ContractTypes.SportsbookPushResponse) => {
        this.add(response.data.add, response.metadata);
        this.change(response.data.change, response.metadata);
        this.remove(response.data.remove, response.metadata);
    };

    private add = (
        payload: ContractTypes.SportsbookPushResponse['data']['add'],
        metaData: ContractTypes.SportsbookPushResponse['metadata']
    ) => {
        payload.events.forEach((event) => {
            const { id } = event;
            const newEvent = { ...event };
            this.data.events.set(id, { ...newEvent });
            this.trackSocketEvent('ADD_EVENT', newEvent.id, metaData);
        });
    };

    private change = (payload: ContractTypes.SportsbookPushResponse['data']['change'], metadata) => {
        payload.events.forEach((newEvent) => {
            const existEvent = this.data.events.get(newEvent.id);
            if (existEvent) {
                eventDeepMerge(existEvent, {
                    ...newEvent
                });
                this.trackSocketEvent('UPDATE_EVENT', newEvent.id, metadata);
            }
        });
    };

    private remove = (
        payload: ContractTypes.SportsbookPushResponse['data']['remove'],
        metaData: ContractTypes.SportsbookPushResponse['metadata']
    ) => {
        payload.events.forEach((event) => {
            const existingEvent = this.data.events.get(event);
            if (existingEvent) {
                existingEvent.status = 'FINISHED';
                this.trackSocketEvent('REMOVE_EVENT', event, metaData);
            }
        });
    };

    private onErrorCallback = (error: unknown) => {
        this.logError({
            statusCode: 'SocketConnectionError',
            description: getErrorDescription(error),
            id: this.leagueId,
            widgetVersion: APP_VERSION
        });
    };

    private trackSocketEvent(clientAction: string, eventId?: string, metadata?: ContractTypes.Metadata) {
        if (eventId) {
            const receivedAt = new Date().getTime();
            const publishedAt = metadata?.publishedTime ? new Date(metadata.publishedTime).getTime() : 0;
            const delta = publishedAt ? receivedAt - publishedAt : 0;
            this.trackEvent('LONGSHOT_EVENT', {
                clientAction,
                delta,
                leagueId: this.leagueId,
                eventId: eventId || '',
                pageName: 'EVENT_PAGE',
                widget: 'SPORT_SLIDER_WIDGET',
                widgetVersion: APP_VERSION
            });
        } else {
            this.trackEvent('LONGSHOT_EVENT', {
                clientAction,
                entity: 'eventGroup',
                leagueId: this.leagueId,
                page: 'EVENT_PAGE'
            });
        }
    }
}
