import { ContractTypes } from '@draftkings/dk-data-layer';
import { BaseRetrieverOptions } from '@draftkings/widgets-core/src/retriever';
import { IConditionManager, ICondition } from '@draftkings/widgets-core/src/contracts';
import { TrackEventFunction, SportSliderStatusError } from '../external';

export const ConditionKeys = ['Retriever'] as const;

export type SubscriptionPartial = {
    entity: string;
    query: string;
};

export type SherpaData = {
    events: Map<string, ContractTypes.SportEvent>;
    leagues: Map<string, ContractTypes.League>;
};

export type ClankData = {
    events: Map<string, ContractTypes.SportEvent>;
    leagues: Map<string, ContractTypes.League>;
};

export type ClankОptions = BaseRetrieverOptions<ClankData> & {
    Clank: ContractTypes.ClankConstructor;
    SportsbookEvent: ContractTypes.SportsbooksEventConstructor;
    condition: IConditionManager<typeof ConditionKeys> & ICondition;
    widgets: ContractTypes.Widget[];
    zoneId: string;
    logError: (error: SportSliderStatusError) => void;
    trackEvent: TrackEventFunction;
    consumerVersion: string;
};

export type SherpaOptions = BaseRetrieverOptions<SherpaData> & {
    Sherpa: ContractTypes.SherpaConstructor;
    SportsbookLeague: ContractTypes.SportsbookLeagueConstructor;
    condition: IConditionManager<typeof ConditionKeys> & ICondition;
    leagueId: string;
    logError: (error: SportSliderStatusError) => void;
    trackEvent: TrackEventFunction;
    consumerVersion: string;
};
