export const getErrorDescription = (error: unknown): string => {
    let description: string;

    if (error instanceof Error) {
        description = error.message;
    } else {
        description = JSON.stringify(error);
    }

    return description;
};
