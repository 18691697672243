export type SGPBadgeProps = {
    className?: string;
    fillBackground: string;
    fill: string;
};

export const SGPBadgeDark: SGPBadgeProps = {
    fillBackground: '#12320B',
    fill: '#53D337'
};

export type ButtonProps = {
    text: string;
    infoText: string;
    onClick: () => void;
};
