import { makeObservable, computed } from 'mobx';
import { IState, IParser, StateOptions, SBMessageBus } from '../contracts';
import { ICondition } from '@draftkings/widgets-core/src/contracts';
import { DataCondition } from '@draftkings/widgets-core/src/utils/Condition';
type SportSliderParserMobx = 'cards' | 'isError' | 'isLoaded' | 'isLoading';

export class State implements IState {
    private parser: IParser;
    private messageBus: SBMessageBus;
    private readonly condition: ICondition;
    constructor(options: StateOptions) {
        this.parser = options.parser;
        this.messageBus = options.messageBus;
        this.condition = options.condition;
        makeObservable<typeof this, SportSliderParserMobx>(this, {
            cards: computed,
            isError: computed,
            isLoaded: computed,
            isLoading: computed
        });
    }

    get cards() {
        return [...this.parser.cards];
    }

    get isError() {
        return this.condition.value === DataCondition.ERROR;
    }
    get isLoaded() {
        return this.condition.value === DataCondition.LOADED;
    }
    get isLoading() {
        return this.condition.value === DataCondition.LOADING;
    }

    onCardClick = (id: string, name: string, isSgp: boolean): void => {
        this.messageBus.emit('slider_on_select_id', {
            id,
            name,
            isSgp
        });
        this.parser.toggleCardId(id);
    };

    handleSliderSelectId = ({ id }: { id: string }): void => {
        if (id) {
            this.parser.toggleCardId(id);
        }
    };

    activate(): void {
        this.messageBus.on('slider_toggle_id', this.handleSliderSelectId);
        this.parser.activate();
    }

    deactivate(): void {
        this.messageBus.off('slider_toggle_id', this.handleSliderSelectId);
        this.parser.deactivate();
    }
}
