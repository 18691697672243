import React from 'react';
import { TileSlider } from '../TileSlider';
import { SliderCard } from '../../contracts';
import { getDefaultValue } from '../../utils';
import { EventCard, LeagueCard } from '../Cards';
import './index.scss';

export type SliderProps = {
    cards: SliderCard[];
    onClick: (id: string, name: string, isSgp: boolean) => void;
};

export type Slidertate = {
    sliderWidth: number;
};
export class Slider extends React.Component<SliderProps, Slidertate> {
    static defaultProps = {
        widgets: []
    };

    parentRef: HTMLDivElement | null = null;

    constructor(props: SliderProps) {
        super(props);

        this.state = {
            sliderWidth: 0
        };
    }

    componentDidMount() {
        window.addEventListener('resize', this.setWidth);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.setWidth);
    }

    setParentRef = (r: HTMLDivElement) => {
        if (!this.parentRef && r) {
            this.parentRef = r;
            this.setState({ sliderWidth: r.offsetWidth });
        }
    };

    setWidth = () => {
        if (this.parentRef) {
            this.setState({ sliderWidth: this.parentRef.offsetWidth });
        }
    };

    createWidgetCards = (cards: SliderCard[]) => {
        return cards.map((card: SliderCard) => {
            if (card.type === 'event') {
                return (
                    <EventCard
                        key={card.id}
                        {...card}
                        onSgpClick={(event) => {
                            event.preventDefault();
                            event.stopPropagation();
                            this.props.onClick(card.id, card.name, true);
                        }}
                    />
                );
            }
            return <LeagueCard key={card.id} {...card} />;
        });
    };

    onCardClick = (id: number | string) => {
        const cardId = String(id);
        const { cards } = this.props;
        const { name } = getDefaultValue(
            cards.find((card) => card.id === cardId),
            {
                description: '',
                id: '',
                isLive: false,
                isSelected: false,
                isSgp: false,
                period: '',
                startDate: '',
                name: '',
                teams: [],
                scores: ['', ''],
                timer: '',
                type: 'event',
                href: 'url'
            }
        );
        this.props.onClick(cardId, name, false);
    };

    render() {
        const { cards } = this.props;
        const fixedTileWidth = 172; // width of each tile for TileSlider calculations

        const tiles = this.createWidgetCards(cards);

        return (
            <div className="slider-cards" data-testid="slider-cards">
                <div ref={this.setParentRef}>
                    <TileSlider
                        sliderWidth={this.state.sliderWidth + 200}
                        tileWidth={200}
                        scrollMode={this.state.sliderWidth <= 768}
                        tileWidths={tiles.map(() => fixedTileWidth)}
                        dynamicSlide
                        onClick={this.onCardClick}
                        offset={0}
                        shouldCalculateContainerWidth
                        arrowDisplay=""
                    >
                        {tiles}
                    </TileSlider>
                </div>
            </div>
        );
    }
}
